<template lang="pug">
.form-popup
  .form-popup__div(v-show="!sendForm")
    h2.center(v-html="formTitle")
    .form-popup__fields
      .form-popup__field
        .input-text
          input.input-text__input(
            type="text"
            :placeholder="formFieldNamePlaceholder"
            v-model="fieldName"
          )

      .form-popup__field
        .input-text
          input.input-text__input(
            type="tel"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
            v-model="fieldPhone"
            :class="{'input-text__input-error': fieldError && v$.$invalid}"
          )

      .form-popup__field
        .input-textarea
          textarea.input-textarea__input(v-model="fieldText" :placeholder="formFieldTextPlaceholder")

    .form-funeral__consent
      .consent.consent-width
        input.consent__input(type="checkbox" id="form-checkbox-product" v-model="consent" value="consent" checked="checked")
        label.consent__label(for="form-checkbox-product" v-html="formConsentText")

    .form-funeral__button
      .button(@click="getSend()")
        .button__name(v-html="formButtonName")

  .form-popup__div(v-show="sendForm")
    h2.center(v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormPopupProduct',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldText: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            number: '7',
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldText = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3500)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
