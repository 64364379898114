/*global $*/
/*eslint no-undef: "error"*/

export default class ShowHidden {
    classBlock = 'show-hidden--block'
    classButton = 'show-hidden--button'
    classText = 'show-hidden--text'

    classBlockActive = 'show-hidden-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textShow = $(this).data('show')
            let textHidden = $(this).data('hidden')

            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockActive)

            if ($(this).closest(`.${self.classBlock}`).hasClass(self.classBlockActive)) {
                $(this).closest(`.${self.classBlock}`).find(`.${self.classText}`).text(textHidden)
            } else {
                $(this).closest(`.${self.classBlock}`).find(`.${self.classText}`).text(textShow)
            }

            console.log(textShow)
            console.log(textHidden)
        })
    }
}
