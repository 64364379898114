/*global $*/
/*eslint no-undef: "error"*/
export default class Price {
    classButtons = 'price--buttons'
    classButton = 'price--button'

    classBlocks = 'price--blocks'
    classBlock = 'price--block'

    classButtonActive = 'price-tab__item-active'
    classBlockActive = 'price__block-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classButtons}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(`.${self.classBlocks}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(`.${self.classBlocks}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlockActive)
        })
    }
}
