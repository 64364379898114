/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import MenuMobile from '@/assets/js/Menu/MenuMobile/MenuMobile'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import FaqItem from '@/assets/js/Faq/FaqItem'
import TabsContact from '@/assets/js/Tabs/TabsContact/TabsContact'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Cookie from '@/assets/js/Cookie/Cookie'
import Price from '@/assets/js/Price/Price'
import MapIframe from '@/assets/js/Map/Iframe/MapIframe'
import MapBackground from '@/assets/js/Map/Background/MapBackground'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import ButtonTab from '@/assets/js/Button/Tab/ButtonTab'
import form from '@/store/form'
import AppFormMain from '@/components/Form/FormMain/AppFormMain'
import AppFormPopupPrice from '@/components/Form/FormPopupPrice/AppFormPopupPrice'
import AppFormPopupConsult from '@/components/Form/FormPopupConsult/AppFormPopupConsult'
import AppFormPopupOrder from '@/components/Form/FormPopupOrder/AppFormPopupOrder'
import AppFormReview from '@/components/Form/FormReview/AppFormReview'
import AppFormPopupFuneral from '@/components/Form/FormPopupFuneral/AppFormPopupFuneral.vue'
import AppFormPopupProduct from '@/components/Form/FormPopupProduct/AppFormPopupProduct.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    new HeaderMobile()
    new MenuMobile()
    new ShowHidden()
    new FaqItem()
    new TabsContact()
    new SwiperSlider()
    new Cookie()
    new Price()
    new MapIframe()
    new MapBackground()
    new ButtonTop()
    new ButtonTab()

    if(document.getElementById('form--main')!= null) {
        createApp(AppFormMain).use(form).use(Maska).mount('#form--main')
    }

    if(document.getElementById('form--price')!= null) {
        createApp(AppFormPopupPrice).use(form).use(Maska).mount('#form--price')
    }

    if(document.getElementById('form--consult')!= null) {
        createApp(AppFormPopupConsult).use(form).use(Maska).mount('#form--consult')
    }

    if(document.getElementById('form--order')!= null) {
        createApp(AppFormPopupOrder).use(form).use(Maska).mount('#form--order')
    }

    if(document.getElementById('form--review')!= null) {
        createApp(AppFormReview).use(form).use(Maska).mount('#form--review')
    }

    if(document.getElementById('block--funeral') != null) {
        createApp({})
            .component('block-funeral', AppFormPopupFuneral)
            .use(Maska)
            .use(form)
            .mount('#block--funeral')
    }

    if(document.getElementById('block--product') != null) {
        createApp({})
            .component('block-product', AppFormPopupProduct)
            .use(Maska)
            .use(form)
            .mount('#block--product')
    }
})
