/*global $*/
/*eslint no-undef: "error"*/

export default class Cookie {
  classKey = 'cookie-bottom-ritcentr'

  classButton = 'cookie--button'
  classCookie = 'cookie'
  classCookieOpen = 'cookie-open'

  constructor () {
    let self = this
    let objCookie = $(`.${self.classCookie}`)

    if (localStorage.getItem(self.classKey) === null) {
      objCookie.addClass(self.classCookieOpen)
    }

    $(`.${self.classButton}`).click(function () {
      localStorage.setItem(self.classKey, 'da')
      objCookie.removeClass(self.classCookieOpen)
    })
  }
}
