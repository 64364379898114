<template lang="pug">
.form-funeral
  .form-funeral__div(v-show="!sendForm")
    h2.center(v-html="formTitle")
    .form-funeral__text
      p.center(v-html="formText")

    .form-funeral__blocks
      .form-funeral__block
        .form-funeral__sub(v-html="formBlock1Title")

        .form-funeral__sections
          .form-funeral__section(v-for="(item1, key1) in fieldBlock1List" :key="key1" :class="{'form-funeral__section-active': item1 === fieldBlock1}" @click="changeSection(item1)")
            .form-funeral__section-name(v-html="item1")

      .form-funeral__block
        .form-funeral__sub(v-html="formBlock2Title")

        .form-funeral__checks
          .form-funeral__check(v-for="(item2, key2) in fieldBlock2List" :key="key2")
            label.input-check
              input.input-check__input(type="checkbox" :value="item2" v-model="fieldBlock2")

              .input-check__buttons
                .input-check__button
                  svg.input-check__icon
                    use(xlink:href="#icon-check")

                .input-check__value(v-html="item2")

    .form-funeral__fields
      .input-text
        input.input-text__input(
          type="text"
          :placeholder="formFieldNamePlaceholder"
          v-model="fieldName"
        )

      .input-text
        input.input-text__input(
          type="tel"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          v-model="fieldPhone"
          :class="{'input-text__input-error': fieldError && v$.$invalid}"
        )

    .form-funeral__consent
      .consent.consent-width
        input.consent__input(type="checkbox" id="form-checkbox-funeral" v-model="consent" value="consent" checked="checked")
        label.consent__label(for="form-checkbox-funeral" v-html="formConsentText")

    .form-funeral__button
      .button(@click="getSend()")
        .button__name(v-html="formButtonName")

  .form-funeral__div(v-show="sendForm")
    h2.center(v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormPopupFuneral',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formType",

    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formText",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldBlock1",
    "formFieldBlock2",
    "formFieldPage",

    "formBlock1Title",
    "formBlock1List",
    "formBlock2Title",
    "formBlock2List",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldBlock1: '',
      fieldBlock1List: '',
      fieldBlock2: [],
      fieldBlock2List: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    changeSection (value) {
      this.fieldBlock1 = value
    },

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            number: '3',
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldBlock1,
              value: this.fieldBlock1
            },
            {
              title: this.formFieldBlock2,
              value: (this.fieldBlock2.length > 0)? this.fieldBlock2.join(';'): ''
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''
        this.fieldBlock2 = []

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3500)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.fieldBlock1List = this.formBlock1List.split(';')
    this.fieldBlock1 = this.fieldBlock1List[0]

    this.fieldBlock2List = this.formBlock2List.split(';')
  }
}
</script>
