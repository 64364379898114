/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonTab {
    classButton = 'button--tab'
    classHeader = 'page-header'
    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function (e) {
            let block = $(this).data('block')
            let heightHeader = $(`.${self.classHeader}`).height() + 10

            console.log(heightHeader)

            $('html,body').stop().animate(
                {
                    scrollTop: $(`#${block}`).offset().top - heightHeader
                },
                500
            )
            e.preventDefault()
            return false
        })
    }
}
