/*global $*/
/*eslint no-undef: "error"*/
export default class FaqItem {
    classBlock = 'faq--block'
    classButton = 'faq--button'

    classBlockActive = 'faq-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockActive)
        })
    }
}
